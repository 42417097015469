<template>
  <div style="width: 100%; height: 100vh;">
    <div style="font-size: 32px; line-height: 40px; color: #1482f0; position: relative; top: 40vh; margin-left: 45%">
      <span style="margin-right: 20px">考试已结束!</span>
      <el-button class="quit" @click="handleLogout">退出</el-button>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'End',
  computed: {
    ...mapGetters(['examineeInfo', 'examStarted'])
  },

  methods: {
    handleLogout() {
      this.$store.dispatch('logout')
        .then(() => {
          this.$message.success('退出登录成功')
          this.$router.replace({
            path: '/login'
          })
        })
    }
  }
}
</script>

<style scoped>
</style>
